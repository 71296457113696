<template>
    <section>
        <!-- 2024/04/02 张晓瑜押金转移 -->
        <r-e-dialog title="押金转移" :visible.sync="dialogVisible" show-footer top="10vh" width="550px"
                    @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
            <el-form ref="formPublish" label-width="100px" size="small" :model="formPublish" :rules="rules">
                <div class="flex">
                    <el-form-item label="当前合同">
                        <el-input v-model="contractNum" style="width: 365px;" disabled/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="支付金额">
                        <el-input v-model="info" style="width: 365px;" disabled/>
                    </el-form-item>
                </div>
                <!-- <div class="flex">
                    <el-form-item label="转移合同" prop="contractUuid">
                        <el-select v-model="formPublish.contractUuid" style="width: 365px;" placeholder="请选择付款方式">
                            <el-option v-for="(item,index) in contractList" :key="index" :label="item.contractNum"
                                       :value="item.uuid"/>
                        </el-select>
                    </el-form-item>
                </div> -->
                <div class="flex" style="margin-left: 30px;color: red">
                    注: 将已缴金额转移至押金余额
                </div>
            </el-form>
        </r-e-dialog>
    </section>
</template>

<script>
import {getContractListByUserAndHouse, updateDepositBalance} from "@/api/bill";
import {MessageWarning,MessageSuccess} from "@custom/message";
import {getDepositAmountapi} from "@/api/tenant-management";
import {numberFormat} from "@custom";

export default {
    name: "layer-transfer-deposit-bill",
    data() {
        return {
            dialogVisible: false,
            formPublish: {
                billUuid: null,
                contractUuid: null,
            },
            rules: {
                contractUuid: [{required: true, message: '请选择转移合同', trigger: 'change'}],
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            contractNum: null,
            info:null,
            contractList: [],
        };
    },
    components: {},
    methods: {
        openDialog(data) {
            const {leasorUuid, apartmentUuid, contract: {uuid: contractUuid, contractNum},uuid} = data;
            let that = this;
            that.contractNum = contractNum;
            that.formPublish.billUuid = uuid;
            that.info = ''; // 清空支付金额的值
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});



            // getContractListByUserAndHouse({leasorUuid, apartmentUuid, contractUuid}).then(res => {
            //     const {list} = res;
            //     if (list.length > 0) {
            //         that.formPublish.billUuid = uuid;
            //         that.contractNum = contractNum;
            //         that.contractList = list;
            //         that.dialogVisible = true;
            //     } else MessageWarning("当前房源没有其他合同,无法转移押金账单", 1000);
            // }).finally(() => loading.close());
            // 押金余额转移获取押金金额
            getDepositAmountapi({uuid}).then(res => {
                        const { info } = res
                        this.info = numberFormat(info / 100);
                        console.log('this.info',this.info);
                        // this.clickCancel();
                        that.dialogVisible = true;
                    }).finally(() => loading.close());
        },
        clickSubmit() {
            let that = this;
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    const {billUuid} = that.formPublish;
                    console.log('billUuid',billUuid);
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    updateDepositBalance({uuid:billUuid}).then(res => {
                        console.log('转移res',res);
                        MessageSuccess('转移成功');
                        that.clickCancel();
                    }).finally(() => loading.close());
                }
            });
        },
        async clickCancel() {
            this.formPublish = {billUuid: null, info: null};
            await this.$emit('clickCancel');
            this.dialogVisible = false;
        },
    },
    created() {
    },
}
</script>

<style scoped>

</style>